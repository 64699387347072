<template>
  <div style="height: 85vh; background-color: white">
    <iframe
      width="70%"
      height="100%"
      frameborder="0"
      :src="`/lib/pdfjs-3.2.146-dist/web/viewer.html?file=${encodeURIComponent(previewUrl)}`"
    >
    </iframe>
    <div style="width: 30%; float: right; height: 85vh; overflow-y: auto">
      <div style="position: relative; margin-top: 34px">
        <el-table
          :data="fileListYear"
          :row-class-name="tableRowClassNameYear"
          @row-click="clickEventYear"
        >
          <el-table-column prop="filename" label="年报"></el-table-column>
        </el-table>
        
        <el-table
          :data="fileList2024"
          :row-class-name="tableRowClassName2024"
          @row-click="clickEvent2024"
        >
        <el-table-column prop="filename" label="2024月报"></el-table-column>
        </el-table>
        
        <el-table
          :data="fileList2023"
          :row-class-name="tableRowClassName2023"
          @row-click="clickEvent2023"
        >
          <el-table-column prop="filename" label="2023月报"></el-table-column>
        </el-table>

        <el-table
          :data="fileList2022"
          :row-class-name="tableRowClassName2022"
          @row-click="clickEvent2022"
        >
          <el-table-column prop="filename" label="2022月报"></el-table-column>
        </el-table>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "pdfShow",
  data() {
    return {
      // previewUrl:"/lib/pdfjs-3.2.146-dist/web/compressed.tracemonkey-pldi-09.pdf",
      
      //  previewUrl:"http://www.gov.cn/zhengce/pdfFile/2022_PDF.pdf",
      fileList2022: window.fileList2022,
      fileList2023: window.fileList2023,
      fileList2024: window.fileList2024,
      fileListYear: window.fileListYear,
      activeIndex2022: window.activeIndex2022,
      activeIndex2023: window.activeIndex2023,
      activeIndex2024: window.activeIndex2024,
      activeIndexYear: window.activeIndexYear,
      urlBase: window.isTest? 'https://fusion.oceanstellar.com/asset/pdf/' : 'https://msmt.oceanstellar.com/asset/pdf/',
      previewUrl: (window.isTest? 'https://fusion.oceanstellar.com/asset/pdf/' : 'https://msmt.oceanstellar.com/asset/pdf/') + window.activeUrl,
    };
  },
  methods: {
    tableRowClassName2022({ row, rowIndex }) {
      if (row.index === this.activeIndex2022) {
        return "success-row";
      }
      return "";
    },
    tableRowClassName2023({ row, rowIndex }) {
      if (row.index === this.activeIndex2023) {
        return "success-row";
      }
      return "";
    },
    tableRowClassName2024({ row, rowIndex }) {
      if (row.index === this.activeIndex2024) {
        return "success-row";
      }
      return "";
    },
    tableRowClassNameYear({ row, rowIndex }) {
      if (row.index === this.activeIndexYear) {
        return "success-row";
      }
      return "";
    },
    clickEvent2022(row, column, event) {
      this.activeIndex2022 = row.index;
      this.activeIndex2023 = "";
      this.activeIndex2024 = "";
      this.activeIndexYear = "";
      this.previewUrl = this.urlBase + row.filename;
    },
    clickEvent2023(row, column, event) {
      this.activeIndex2023 = row.index;
      this.activeIndex2022 = "";
      this.activeIndex2024 = "";
      this.activeIndexYear = "";
      this.previewUrl = this.urlBase + row.filename;
    },
    clickEvent2024(row, column, event) {
      this.activeIndex2024 = row.index;
      this.activeIndex2022 = "";
      this.activeIndex2023 = "";
      this.activeIndexYear = "";
      this.previewUrl = this.urlBase + row.filename;
    },
    clickEventYear(row, column, event) {
      this.activeIndexYear = row.index;
      this.activeIndex2023 = "";
      this.activeIndex2022 = "";
      this.activeIndex2024 = "";
      this.previewUrl = this.urlBase + row.filename;
    },
  },
  components: {},
};
</script>

<style lang="less" scoped>
/deep/.el-table thead {
  font-size: 16px;
  color: black !important;
}
</style>

<style>
.el-table .warning-row {
  background: oldlace;
}

.el-table .success-row {
  background: #f0f9eb;
}
.el-table tbody tr:hover > td {
  background-color: #f0f9eb !important;
}
</style>
